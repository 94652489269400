import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";

import imgL from "../assets/image/png/miles.png";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero
          username="miles"
          fullname="Miles Robinson"
          homegym="10th Planet San Mateo | Anywhere in Bay Area"
          pricing={<a>$100 for 1 student or <br/>2 students $65 each</a>}
          profile={`
Miles is a holistic lifestyle and exercise coach. Miles’ passion for fitness began at thirteen years
old and since then he has been on a relentless pursuit to discover different methodologies for
optimizing human health and function. Miles first learned to program workouts for clients at the
Riekes Center in Menlo Park in high school. Miles graduated college and for the last six years
has been helping people reach their fitness and wellness goals as a career. Miles is certified by
NSCA-CPT and has two years of training at the Chek Institute for holistic health and exercise.
Miles works with everyone from stay-at-home parents who need to alleviate chronic pain and
gain energy to be their best for their kids to dedicated athletes determined to gain an edge in
competition. Miles uses a wide variety of both traditional and unorthodox functional training
methods and he is always learning and evolving to add new tools to his coaching tool box. Miles
also competes in Jiu Jitsu and Mixed Martial arts actively and maintains a healthy lifestyle and
never teaches anything he has not tested himself so he can “walk the walk” as a coach.
            `}
          imgL={imgL}
          calendly={"https://calendly.com/milesrbnsn/1h"}
        />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
